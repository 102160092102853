import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import {
  ContentFormat,
  ResourceFolder,
  ShortFormVideo,
  SocialAccount,
  Superscript,
  User,
  parseVLError,
} from "kre8tv/model";
import {
  IUserCallbackResult,
  IUserFullProfileCallbackResult,
  IUserMainCallbackResult,
} from "kre8tv/model/interfaces/user-callback-result.interface";

export const fetchCurrentUserDetails =
  async (): Promise<IUserMainCallbackResult> => {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    try {
      if (!token) throw new Error("No token found");

      let url = API_BASE_URL + `users`;

      const headers = {
        Authorization: `Bearer ${token}`,
        platform: `web`,
      };

      const config: AxiosRequestConfig = {
        headers: headers,
        timeout: 25000,
      };

      const fetchResult = await axios.get(url, config);

      // console.log("fetchCurrentUserDetails result = ", fetchResult);

      const { data } = fetchResult;
      const { user, socialAccounts } = data;

      return {
        user: new User(user),
        socialAccounts: socialAccounts.map(
          (socialAccount: any) => new SocialAccount(socialAccount)
        ),
      };
    } catch (error: any) {
      console.error("error fetchCurrentUserDetails= ", error);
      throw new Error(error);
    }
  };

/**
 * Fetches the full profile details for the current user
 * @returns Object containing scripts, videos, and formats created by the current user
 */
export const fetchCurrentUserFullProfileDetails =
  async (): Promise<IUserFullProfileCallbackResult> => {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    try {
      let url = API_BASE_URL + `users`;

      const headers = {
        Authorization: `Bearer ${token}`,
        user_id: firebase.auth().currentUser?.uid,
      };

      const config: AxiosRequestConfig = {
        headers: headers,
        timeout: 15000,
      };

      const fetchResult = await axios.get(url, config);

      // console.log("fetchCurrentUserDetails result = ", fetchResult);

      const { data } = fetchResult;
      const { scripts, videos, formats } = data;

      return {
        scripts: scripts.map((json: any) => new Superscript(json)),
        videos: videos.map((json: any) => new ShortFormVideo(json)),
        formats: formats.map((json: any) => new ContentFormat(json)),
      };
    } catch (error: any) {
      console.error("error fetchCurrentUserDetails= ", error);
      throw new Error(error);
    }
  };

export const updateUserDetails = async (
  first_name: string,
  last_name: string,
  email: string
): Promise<IUserCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 13500,
    };

    const params = {
      action: "update",
      first_name: first_name,
      last_name: last_name,
      email: email,
    };

    const saveResult = await axios.post(url, params, config);

    // console.log("save result = ", saveResult);

    const { data } = saveResult;

    return { user: new User(data) };
  } catch (error: any) {
    console.error("error updateUserDetails= ", error);
    throw new Error(error);
  }
};

export const updateUserProfile = async (
  first_name: string,
  last_name: string,
  profile_id: string,
  profilePictureURL: string,
  title: string,
  tags: string[],
  target_audience: string
): Promise<IUserCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const user_id = firebase.auth().currentUser?.uid;
    if (!user_id)
      throw new Error("You must be logged in to update your profile");

    let url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 13500,
    };

    // console.log("calling updateUserProfile with params = ", {
    //   action: "update_profile",
    //   id: user_id,
    //   update_type: "account",
    //   first_name: first_name,
    //   last_name: last_name,
    //   profile_id: profile_id,
    //   profilePictureURL: profilePictureURL,
    //   title: title,
    //   tags: tags,
    //   target_audience: target_audience,
    // });

    const params = {
      action: "update_profile",
      id: user_id,
      update_type: "account",
      first_name: first_name,
      last_name: last_name,
      profile_id: profile_id,
      profilePictureURL: profilePictureURL,
      title: title,
      tags: tags,
      target_audience: target_audience,
    };

    const saveResult = await axios.post(url, params, config);

    // console.log("save result = ", saveResult);

    const { data } = saveResult;
    const user = new User(data);
    if (!user.id) {
      throw new Error("Failed to update user profile");
    }

    return { user: user };
  } catch (error: any) {
    console.error("error updateUserProfile= ", error);
    throw new Error(error);
  }
};

export const getUserHomeFolders = async (): Promise<{
  with_folders: { folder: ResourceFolder; scripts: Superscript[] }[];
  without_folders: Superscript[];
}> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const user_id = firebase.auth().currentUser?.uid;
    if (!user_id || !token) {
      throw new Error("You must be logged in to get your home folders");
    }

    let url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: { action: "get_user_home_folders" },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("fetchUserHomeFolders result = ", fetchResult);

    const { data } = fetchResult;
    const { results } = data;
    const { with_folders, without_folders } = results;

    return {
      with_folders: with_folders.map((json: any) => {
        const { folder, scripts } = json;

        return {
          folder: new ResourceFolder(folder),
          scripts: scripts && scripts.map((json: any) => new Superscript(json)),
        };
      }),
      without_folders:
        without_folders &&
        without_folders.map((json: any) => new Superscript(json)),
    };
  } catch (error: any) {
    console.error("error fetchUserHomeFolders= ", error);
    throw new Error(error);
  }
};

/**
 * Deletes a user asset from the server
 * @param asset The asset to delete
 * @returns The deleted asset
 */
export const deleteAccount = async (): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "delete_account",
      firebase_refresh_token: firebase.auth().currentUser?.refreshToken,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
      data: params,
    };

    const fetchResult = await axios.delete(url, config);

    // console.log("deleteAccount result = ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const success = result.success;
    if (!success) {
      throw new Error("Error deleting account");
    }

    return { success: success };
  } catch (error: any) {
    console.error("error deleting account= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const verifyHCaptcha = async (
  token: string
): Promise<{ success: boolean }> => {
  //grab token
  const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = firebaseToken;

  try {
    const url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
      platform: `web`,
    };

    let params = {
      action: "verify_h_capcha",
      token: token,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
      data: params,
    };

    const fetchResult = await axios.post(url, params, config);

    console.log("verifyHCaptcha result = ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const success = result?.verified;
    if (!success) {
      throw new Error("Error verifying captcha");
    }

    return { success: success };
  } catch (error: any) {
    console.error("error verifying captcha= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

//region - external apis
/**
 * Generates and assigns an external API key for the current user.
 * @param {string} userId - The ID of the user to generate and assign the API key to.
 * @returns {Promise<{apiKey: string}>} The generated API key.
 */
export const generateAndAssignExternalAPIKey = async (
  userId: string
): Promise<{ apiKey: string }> => {
  try {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);
    const url = `${API_BASE_URL}users`;
    const params = {
      action: "generate_and_assign_api_key",
      user_id: userId,
    };
    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    const response = await axios.post(url, params, { headers });
    return response.data.result;
  } catch (error) {
    console.error("error generating and assigning api key: ", error);
    throw error;
  }
};

/**
 * Removes the external API key for the current user.
 * @param {string} userId - The ID of the user to remove the API key from.
 * @returns {Promise<{success: boolean}>} The result of the removal operation.
 */
export const removeExternalAPIKey = async (
  userId: string
): Promise<{ success: boolean }> => {
  try {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);
    const url = `${API_BASE_URL}users`;
    const params = {
      action: "remove_api_key",
      user_id: userId,
    };
    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    const response = await axios.post(url, params, { headers });
    return response.data.result;
  } catch (error) {
    console.error("error removing api key: ", error);
    throw error;
  }
};

export const saveWebhookURLs = async (
  webhookURL: string
): Promise<{ success: boolean }> => {
  try {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);
    const url = `${API_BASE_URL}users`;
    const params = {
      action: "save_webhook_url",
      webhook_url: webhookURL,
    };
    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    const response = await axios.post(url, params, { headers });
    return response.data.result;
  } catch (error) {
    console.error("error saving webhook urls: ", error);
    throw error;
  }
};

export const deleteWebhookURL = async (
  webhookURL: string
): Promise<{ success: boolean }> => {
  try {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);
    const url = `${API_BASE_URL}users`;
    const params = {
      action: "delete_webhook_url",
      webhook_url: webhookURL,
    };
    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    const response = await axios.post(url, params, { headers });
    // console.log("deleteWebhookURL response = ", response);
    return response.data.result;
  } catch (error) {
    console.error("error deleting webhook url: ", error);
    throw error;
  }
};

/**
 * Triggers a refresh of the user's membership status
 * @returns
 */
export const updateMembershipStatus = async (): Promise<{
  success: boolean;
}> => {
  try {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);
    const url = `${API_BASE_URL}users`;
    const params = {
      action: "update_membership_status_from_web_app",
    };
    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    const response = await axios.post(url, params, { headers });
    // console.log("updateMembershipStatus response = ", response);
    return response.data.result.success;
  } catch (error) {
    console.error("error deleting webhook url: ", error);
    throw error;
  }
};

/**
 * Submits an invitation code for validation
 * @param code The invitation code
 * @param email User's email
 * @returns Result of code submission
 */
export const submitInvitationCode = async (
  code: string
): Promise<{ message: string; approve: boolean }> => {
  try {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken(true);
    const url = `${API_BASE_URL}users`;
    const params = {
      action: "submit_invitation_code",
      code,
    };
    const headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    const response = await axios.post(url, params, { headers });
    return response.data;
  } catch (error: any) {
    console.error("Error submitting invitation code:", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
